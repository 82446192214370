import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button} from "antd";

import IMAGES from "../../../utils/images";
import PAGE_ROUTES from "../../../utils/page-routes";
import ApiService from "../../../services/api/ApiService";
import Error from "../../../components/ui/Error";


import styles from "../styles/login_page.module.scss";



function LoginPage() {
  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onLogin = (body) => {
    setLoading(true);
    ApiService.login(body)
      .then((response) => {
        const token = response?.data?.token;
        if(!token) {
          throw new Error();
        }
        localStorage.setItem("token", token);
        navigate(PAGE_ROUTES.DASHBOARD);
      })
      .catch((error) => {
        setError("Incorrect login or password. Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  

  const onFinish = async (values) => {
    setError("");
    onLogin(values);
  };

  const onFinishFailed = () => {
    setError("");
  };

  const trimAndValidate = (fieldName, validator) => (_, value) => {
    if (value && typeof value === "string") {
      const trimmedValue = value.replace(/\s+/g, " ").trim();
      form.setFieldsValue({ [fieldName]: trimmedValue });
      return validator(trimmedValue);
    }
    return Promise.resolve();
  };

  const passwordValidator = (value) => {
    if (!value || value.length >= 8) {
      return Promise.resolve();
    }
    return Promise.reject("Password must be at least 8 characters!");
  };

  return (
    <div className={styles["login-page"]}>
      <div className={styles["transparent-background"]}>
        <div className={styles["form-container"]}>
          <img src={IMAGES.LOGO} alt="Logo" />

          <Form
            name="login-form"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className={styles["login-form"]}
          >
            <h1>Welcome to the XDGO Panel</h1>

            <Form.Item
              name="username"
              rules={[
                {
                  validator: trimAndValidate("username", (value) =>
                    Promise.resolve()
                  )
                },
                { required: true, message: "Please input your username!" }
              ]}
            >
              <Input placeholder="Username" className={styles["input"]} />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { validator: trimAndValidate("password", passwordValidator) },
                { required: true, message: "Please input your password!" }
              ]}
            >
              <Input.Password
                placeholder="Password"
                className={styles["input"]}
              />
            </Form.Item>

            <Button
              type="primary"
              loading={loading}
              className={styles["login-button"]}
              onClick={() => form.submit()}
            >
              Log In
            </Button>
            <div className={styles["error"]} >
              {error && <Error message={error} />}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;

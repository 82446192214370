import { ExclamationCircleOutlined } from '@ant-design/icons';

import styles from '../styles/error.module.scss'; 

const Error = ({ message }) => (
  <div className={styles.error}>
    <ExclamationCircleOutlined className={styles.errorIcon} />
    {message}
  </div>
);

export default Error;

import { useState, useEffect } from "react";
import { Layout, theme } from "antd";

import CustomSider from "./components/CustomSider";
import CustomHeader from "./components/CustomHeader";
import CustomContent from "./components/CustomContent";

const CustomLayout = () => {

  const [isMobile, setIsMobile] = useState(!window.innerWidth <= 768);
  const {token: { colorBgContainer, borderRadiusLG }} = theme.useToken();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Layout>
      {
        !isMobile && <CustomSider />

      }
      <Layout>
        <CustomHeader isMobile={isMobile} setIsMobile={setIsMobile}/>
        <CustomContent
          colorBgContainer={colorBgContainer}
          borderRadiusLG={borderRadiusLG}
        />
      </Layout>
    </Layout>
  );
};
export default CustomLayout;

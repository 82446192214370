import { Drawer } from "antd";

import CustomMenu from "../components/CustomMenu";

import IMAGES from "../../utils/images";

import styles from "../styles/custom_drawer.module.scss"

const CustomDrawer = ({ drawerVisible, onCloseDrawer, isMobile }) => {
  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onCloseDrawer}
      open={drawerVisible}
      width={200}
      bodyStyle={{ padding: 0 }}
    >
          <div className={styles["logo-container"]} >
          <img src={IMAGES.LOGO} alt="Logo" />
        </div>
      <CustomMenu  isMobile={isMobile} onCloseDrawer={onCloseDrawer}/>
    </Drawer>
  );
};

export default CustomDrawer;

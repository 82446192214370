import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import PAGE_ROUTES from "../../../utils/page-routes";

import Loading from "../../../components/ui/Loading";

function SplashScreen() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      navigate(PAGE_ROUTES.DASHBOARD);
    } else {
      navigate(PAGE_ROUTES.LOGIN);
    }
  }, []);

  return (
    <div>
      <Loading />
    </div>
  );
}

export default SplashScreen;

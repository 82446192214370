import React from "react";

import styles from "../styles/loading.module.scss";

function Loading() {
  return (
    <div className={styles["loading-container"]}>
      <div className={styles["box"]}></div>
    </div>
  );
}

export default Loading;

import { useState } from "react";
import { Button, Layout } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import CustomDrawer from "./CustomDrawer";

import styles from "../styles/custom_header.module.scss";


const { Header } = Layout;

const CustomHeader = ({  isMobile }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const onOpenDrawer = () => {
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

 

  return (
    <Header className={styles["header-container"]}>
      {isMobile && (
        <Button
          className={styles["button-container"]}
          type="text"
          icon={drawerVisible ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={onOpenDrawer}
          style={{
            color: "#1e6193",
            fontSize: "16px",
            marginLeft: "8px"
          }}
        />
      )}
   <div>
   <CustomDrawer drawerVisible={drawerVisible} onCloseDrawer={onCloseDrawer} isMobile={isMobile}/>
   </div>
    </Header>
  );
};

export default CustomHeader;

import { useState, useEffect } from "react";
import { BarChartOutlined } from "@ant-design/icons";
import { Pie } from "@ant-design/charts";

import { checkToken } from "../../../utils/actions";
import ApiService from "../../../services/api/ApiService";

import styles from "../styles/dashboard_page.module.scss";

const DashboardPage = () => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    ApiService.getDeviceStatistics()
      .then((response) => {
        setChartData(response.data);
      })
      .catch((error) => {
        checkToken(error)
        console.error("Error fetching device statistics:", error);
      });
  };

  const config = {
    data: chartData,
    angleField: "count",
    colorField: "device_os",
    label: {
      text: "device_os",
      style: {
        fontWeight: "bold"
      }
    },
    legend: {
      color: {
        title: true,
        rowPadding: 5
      }
    }
  };

  return (
    <div className={styles["dashboard-page"]}>
      <div>
        <BarChartOutlined style={{ marginBottom: "30px" }} />
        <span>Dashboard</span>
      </div>

      <div className={styles["devices-statistics-chart"]}>
        <Pie key={chartData.length} {...config} />
      </div>
    </div>
  );
};

export default DashboardPage;

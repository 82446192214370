import { ConfigProvider } from "antd";
import { Routes, Route } from "react-router-dom";

import Layout from "../src/layout/Layout";

import PAGE_ROUTES from "./utils/page-routes";

import DNSPage from "./pages/dns/components/DnsPage";
import LoginPage from "./pages/login/components/LoginPage";
import DashboardPage from "./pages/dashboard/components/DashboardPage";
import SplashScreen from "./pages/splash-screen/components/SplashScreen";

import "./antd.scss";

function App() {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: "rgba(74, 174, 173, 1)",
                        algorithm: true,
                    },
                    Input: {
                        colorPrimary: "rgba(74, 174, 173, 1)",
                        algorithm: true,
                    },
                },
            }}
        >
            <Routes>
                <Route path="/" element={<SplashScreen />} />
                <Route path={PAGE_ROUTES.LOGIN} element={<LoginPage />} />
                <Route path={PAGE_ROUTES.ROOT} element={<Layout />}>
                    <Route path={PAGE_ROUTES.DNS} element={<DNSPage />} />
                    <Route path={PAGE_ROUTES.DASHBOARD} element={<DashboardPage />} />
                </Route>
            </Routes>
        </ConfigProvider>
    );
}

export default App;

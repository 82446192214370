import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Menu } from "antd";
import { Modal } from "antd";
import { BarChartOutlined, CloudServerOutlined, LogoutOutlined } from "@ant-design/icons";
import { ExclamationCircleFilled } from "@ant-design/icons";

import PAGE_ROUTES from "../../utils/page-routes";

import styles from "../styles/custom_menu.module.scss";

const CustomMenu = ({ isMobile, onCloseDrawer }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState("");

  useEffect(() => {
    const pathname = location.pathname;
    switch (pathname) {
      case PAGE_ROUTES.DASHBOARD:
        setSelectedKey("1");
        break;
      case PAGE_ROUTES.DNS:
        setSelectedKey("2");
        break;
      default:
        setSelectedKey("");
        break;
    }
  }, [location.pathname]);

  const showLogOutConfirm = () => {
    Modal.confirm({
      title: "Are you sure you want to log out?",
      icon: <ExclamationCircleFilled />,
      okText: "Log Out",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        localStorage.removeItem("token");
        navigate(PAGE_ROUTES.LOGIN);
      },
      onCancel() {
        console.log("Cancel");
      },
      okButtonProps: {
        type: "primary",
        danger: true
      },
      cancelButtonProps: {
        type: "primary",
        danger: false,
        style: {
          backgroundColor: "transparent",
          borderColor: "#116262",
          color: "#116262"
        }
      }
    });
  };

  const handleMenuItemClick = (route) => {
    navigate(route);
    setSelectedKey(route);
    if (isMobile && onCloseDrawer) {
      onCloseDrawer(); 
    }
  };

  return (
    <div className={styles["menu-container"]}>
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[selectedKey]}
      className={styles["menu"]}
    >
      <Menu.Item
        key="1"
        icon={<BarChartOutlined />}
        onClick={() => handleMenuItemClick(PAGE_ROUTES.DASHBOARD)}
      >
        Dashboard
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<CloudServerOutlined />}
        onClick={() => handleMenuItemClick(PAGE_ROUTES.DNS)}
      >
        DNS
      </Menu.Item>
      <Menu.Item key="3" icon={<LogoutOutlined />} onClick={showLogOutConfirm}>
        LOG OUT
      </Menu.Item>
    </Menu>
    <div className={styles["footer"]}>
          App version 1.0.1
        </div>
    </div>
    
  );
};

export default CustomMenu;

const IMAGES = {
  // a
  // b
  // c
  // d
  // e
  // f
  // g
  // h
  // i
  // j
  // k
  // l
  LOGO: require("../assets/images/logo.png")
  // m
  // n
  // o
  // p
  // q
  // r
  // s
  // t
  // u
  // v
  // w
  // x
  // y
  // z
};

export default IMAGES;

import { useEffect, useState } from "react";

import { Button, Modal, Form, Input, message } from "antd";

import ApiService from "../../../services/api/ApiService";

const ModalForDns = ({
  modalVisible,
  setModalVisible,
  editableItem,
  getDnsList
}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (modalVisible) {
      if (editableItem) {
        form.setFields([
          {
            name: "name",
            value: editableItem.name
          },
          {
            name: "host",
            value: editableItem.host
          }
        ]);
      }
    } else {
      form.resetFields();
    }
  }, [modalVisible, editableItem]);

  const onFinish = () => {
    setIsLoading(true);
    if (editableItem) {
      onUpdate();
    } else {
      onSubmit();
    }
  };

  const onUpdate = () => {
    form
      .validateFields()
      .then((values) => {
        const trimmedValues = {
          name: values.name.trim(),
          host: values.host.trim()
        };
        ApiService.updateServer(editableItem.id, trimmedValues)
          .then(() => {
            message.success("Server updated successfully");
            getDnsList();
            handleClose();
          })
          .catch((error) => {
            console.error("Error updating server:", error);
            message.error("Failed to update server. Please try again later.");
          });
      })
      .catch((errorInfo) => {
        console.log("Failed:", errorInfo);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const trimmedValues = {
          name: values.name.trim(),
          host: values.host.trim()
        };
        ApiService.createServer(trimmedValues)
          .then(() => {
            message.success("Server created successfully");
            getDnsList();
            handleClose();
          })
          .catch((error) => {
            message.error(error.response?.data);
          });
      })
      .catch((errorInfo) => {
        console.log("Failed:", errorInfo);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  return (
    <Modal
      open={modalVisible}
      title={editableItem ? "Edit DNS" : "Add DNS"}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,

        <Button
          key="save"
          type="primary"
          loading={isLoading}
          onClick={() => form.submit()}
        >
          {editableItem ? "Update" : "Save"}
        </Button>
      ]}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input name"
            }
          ]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input placeholder="Input name" />
        </Form.Item>
        <Form.Item
          label="Host"
          name="host"
          rules={[
            {
              required: true,
              message: "Please input Host"
            }
          ]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input placeholder="Input Host" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalForDns;

import { Layout } from "antd";
import { Outlet } from "react-router-dom";

import styles from "../styles/custom_content.module.scss";

const { Content: AntdContent } = Layout;

const CustomContent = ({ colorBgContainer, borderRadiusLG,  }) => {
  return (
    <AntdContent
      className={styles["content-container"]}
      style={{
        background: colorBgContainer,
        borderRadius: borderRadiusLG
      }}
    >
      <Outlet/>
    </AntdContent>
  );
};

export default CustomContent;

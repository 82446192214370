import { useState, useEffect } from "react";
import { Button, Modal, Table, message } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { checkToken } from "../../../utils/actions";
import ApiService from "../../../services/api/ApiService";

import ModalForDns from "./ModalForDns";

import styles from "../../dns/styles/dns_page.module.scss"

const DNSPage = () => {

  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [deletableItem, setDeletableItem] = useState(null);
  const [editableItem, setEditableItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getDnsList();
  }, [currentPage]);

  const getDnsList = () => {
    setLoading(true);
    ApiService.getServers({
      query: JSON.stringify({ limit: 10, page: currentPage })
    })
      .then((response) => {
        if (response) {
          setData(response.data.rows);
          setTotal(response.data.total);
          if (response.data.rows.length === 0 && currentPage > 1) {
            setCurrentPage(1);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching DNS data:", error);
        message.error("Failed to fetch DNS data. Please try again later.");
        checkToken(error)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDelete = () => {
    ApiService.deleteServer(deletableItem.id)
      .then(() => {
        setDeletableItem(null);
        getDnsList();
        message.success("Server deleted successfully");
      })
      .catch((error) => {
        setDeletableItem(null);
        message.error("Failed to delete server. Please try again later.");
      });
  };

  const onEdit = (record) => {
    setEditableItem(record);
    setModalVisible(true);
  };

  const onAdd = () => {
    setEditableItem(null);
    setModalVisible(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      width: 60,
      fixed: "left",
      align: "center",
      render: (_, __, index) => (
        <div style={{ textAlign: "center" }}>
          {index + 1 + (currentPage - 1) * 10}
        </div>
      )
    },

    {
      title: "Name",
      dataIndex: "name"
    },

    {
      title: "Host",
      dataIndex: "host"
    },

    {
      title: "Created date",
      dataIndex: "createdAt",
      render: (createdAt) => {
        const datePart = createdAt.split("T")[0];
        return datePart;
      }
    },

    {
      title: "Actions",
      dataIndex: "actions",
      width: 80,
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center"
          }}
        >
          <Button icon={<EditOutlined />} onClick={() => onEdit(record)} />
          <div style={{ width: "10px" }} />
          <Button
          type="primary"
          danger
            icon={<DeleteOutlined />}
            onClick={() => setDeletableItem(record)}
          />
        </div>
      )
    }
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem"
        }}
      >
        <span>DNS</span>
        <Button type="primary" onClick={onAdd}>
          + Add New DNS
        </Button>
      </div>
      <div className={styles["table-container"]}>
      <Table
        scroll={{ x: "max-content", y: "max-content"  }}
        columns={columns}
        dataSource={data.map((item, index) => ({ ...item, key: index }))}
        bordered
        size="small"
        pagination={{
          pageSize: 10,
          total: total,
          position: ["bottomRight"],
          showSizeChanger: false,
          current: currentPage,
          onChange: (page) => setCurrentPage(page)
        }}
        loading={loading}
      />
    </div>
      <ModalForDns
        editableItem={editableItem}
        getDnsList={getDnsList}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
      <Modal
        title="Delete"
        open={deletableItem ? true : false}
        onOk={onDelete}
        onCancel={() => setDeletableItem(null)}
        okText="Delete"
        cancelText="Cancel"
        okButtonProps={{ danger: true, type: "primary" }}
      >
        <p>
          Are you sure you want to delete{" "}
          <span style={{ color: "red" }}>
            {deletableItem ? deletableItem.name : ""}'s
          </span>{" "}
          dns?
        </p>
      </Modal>
    </div>
  );
};

export default DNSPage;

import axios from "axios";

const ApiService = {
  host: "https://xd-api.xtream.cloud/",

  request({ method, path, params }) {
    let options = {
      method,
      url: this.host + path,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const token = localStorage.getItem("token");

    if (token) {
      options.headers.Authorization = `Bearer ${token}`;
    }

    if (method === "get") {
      options.params = params;
    } else {
      options.data = params;
    }

    return axios(options);
  },

  login(body) {
    const data = {
      method: "post",
      path: "auth/login",
      params: body,
    };

    return this.request(data);
  },

  getAdminProfile() {
    const data = {
      method: "get",
      path: "auth/profile",
    };

    return this.request(data)
      .then((data) => data)
      .catch((error) => {
        if (error.response.status === 403) {
          window.location.href = "/";
          localStorage.removeItem("token");
        }

        return error.response;
      });
  },

  getServers(params) {
    const data = {
      method: "get",
      path: "admin/servers",
      params: params,
    };

    return this.request(data);
  },

  createServer(body) {
    const data = {
      method: "post",
      path: "admin/servers",
      params: body,
    };

    return this.request(data);
  },

  updateServer(id, body) {
    const data = {
      method: "put",
      path: `admin/servers/${id}`,
      params: body,
    };

    return this.request(data);
  },

  deleteServer(id) {
    const data = {
      method: "delete",
      path: `admin/servers/${id}`,
    };

    return this.request(data);
  },

  getDeviceStatistics() {
    const data = {
      method: "get",
      path: "admin/devices/registered_chart",
    };

    return this.request(data);
  },
};

export default ApiService;

import {useNavigate } from "react-router-dom";

import { Layout } from "antd";

import IMAGES from "../../utils/images";
import PAGE_ROUTES from "../../utils/page-routes";
import CustomMenu from "../components/CustomMenu";

import styles from "../styles/custom_sider.module.scss";

const { Sider } = Layout;

const CustomSider = () => {
  const navigate = useNavigate();
  return (
    <Sider
      trigger={null}
      collapsible
      className={styles["sider-container"]}
    >
      <div>
        <div
          className={styles["logo-container"]}
          onClick={() => navigate(PAGE_ROUTES.HOME)}
        >
          <img src={IMAGES.LOGO} alt="Logo" />
        </div>

        <div >
          <CustomMenu
          />
        </div>
      </div>
    </Sider>
  );
};

export default CustomSider;


